<template lang="pug">
.footer
  .footer-top
    NuxtLink(to="/")
      picture
        source(
          srcset="~/assets/logo-2x.png 2x, ~/assets/logo-3x.png 3x, ~/assets/logo-4x.png 4x"
        )
        img.footer--logo(
          src="~/assets/logo.png",
          alt="Logo Box Magenta",
          width="112",
          height="90"
        )

    .footer--menu
      a.footer--menu-item(
        href="https://boxmagenta.com.br/blog",
        target="_blank"
      ) Blog
      a.footer--menu-item(
        href="https://atendimento.boxmagenta.com.br/hc/pt-br",
        target="_blank",
        rel="noreferrer noopener"
      ) Perguntas frequentes
      a.footer--menu-item(
        @click="openZendeskWidget()"
      ) Fale com a gente

    .footer--menu
      //- NuxtLink.footer--menu-item(
      //-   v-bind:class="{ active: route.name == 'about' }",
      //-   to="/sobre"
      //- ) Sobre a Magenta
      NuxtLink.footer--menu-item(
        v-bind:class="{ active: route.name == 'terms-of-use' }",
        to="/termos-de-uso"
      ) Termos de uso
      NuxtLink.footer--menu-item(
        v-bind:class="{ active: route.name == 'privacy-policy' }",
        to="/politica-de-privacidade"
      ) Política de privacidade
      NuxtLink.footer--menu-item(
        v-bind:class="{ active: route.name == 'sustainability' }",
        to="/sustentabilidade"
      ) Sustentabilidade

    .footer--spacer

    .footer--social-media
      a(
        href="https://www.instagram.com/boxmagenta",
        target="_blank",
        rel="noreferrer noopener"
      )
        img.footer--social-media-item(
          src="~/assets/icons/social-media/instagram.png",
          alt="Perfil do Instagram da Box Magenta",
          width="144",
          height="144"
        )
      a(
        href="https://www.facebook.com/boxmagenta",
        target="_blank",
        rel="noreferrer noopener"
      )
        img.footer--social-media-item(
          src="~/assets/icons/social-media/facebook.png",
          alt="Perfil do Facebook da Box Magenta",
          width="144",
          height="144"
        )
      a(
        href="https://www.pinterest.com/boxmagentabr",
        target="_blank",
        rel="noreferrer noopener"
      )
        img.footer--social-media-item(
          src="~/assets/icons/social-media/pinterest.png",
          alt="Perfil do Pinterest da Box Magenta",
          width="144",
          height="144"
        )

  .footer-bottom
    .footer-bottom--certificates
      h4 Certificados

      .footer-bottom--certificate-list
        .footer-bottom--certificate(:id="raSealId")

        img.footer-bottom--certificate(
          src="~/assets/footer/selo-eureciclo-verde.svg",
          alt="Selo eureciclo de compensação de reciclagem",
          width="96",
          height="96"
          )

        #armored_website.footer-bottom--certificate
          param(id="aw_preload", value="true")
          param(id="aw_use_cdn", value="true")


    .footer-bottom--company-info
      p Magenta Comércio de Cosméticos LTDA
        br
        | CNPJ 33.144.026/0001-62
        br
        | Av. Comendador Aladino Selmi, 2502 - Módulo 16
        br
        | Parque Cidade Campinas
        br
        | Campinas, São Paulo - 13069-036

</template>
<script lang="ts" setup>
import { onMounted } from "vue"

const props = defineProps({
  raSealId: {
    type: String,
    default: 'reputation-ra'
  }
})

const route = useRoute()

onMounted(() => {
  const siteBlindadoScript = document.createElement("script")
  const reclameAquiScript = document.createElement("script")

  siteBlindadoScript.setAttribute(
    "src",
    "https://cdn.siteblindado.com/aw.js"
  )

  reclameAquiScript.setAttribute(
    "id",
    "ra-embed-reputation"
  )

  reclameAquiScript.setAttribute(
    "src",
    "https://s3.amazonaws.com/raichu-beta/selos/bundle.js"
  )

  reclameAquiScript.setAttribute(
    "data-id",
    "U0ZKR01La2tlZnZ4b2ZkRTpib3gtbWFnZW50YQ=="
  )

  reclameAquiScript.setAttribute(
    "data-target",
    props.raSealId
  )

  reclameAquiScript.setAttribute(
    "data-model",
    "1"
  )

  document.head.appendChild(siteBlindadoScript)
  // document.head.appendChild(reclameAquiScript)
})
</script>
<style lang="sass" scoped>
@import "~/assets/styles/mixins"

.footer
  display: flex
  width: 100%
  flex-direction: column
  justify-content: space-between
  min-height: 200px
  padding: 64px 14px 14px

  .footer-top
    display: flex
    width: 100%
    max-width: 1200px
    margin: 0 auto
    align-items: center
    flex-direction: column
    flex-wrap: wrap
    justify-content: center

    .footer--logo
      flex-grow: 0
      height: 90px
      margin: 0 16px 16px

    .footer--menu
      display: flex
      flex-grow: 0
      flex-direction: column
      align-items: center

      .footer--menu-item
        position: relative
        cursor: pointer
        margin: 8px 0
        padding: 0 16px
        flex-shrink: 1
        font-family: "Alata", sans-serif
        font-size: 16px
        text-align: left

        &:before
          content: ""
          display: block
          position: absolute
          top: -5px
          right: 8px
          height: 6px
          width: 6px
          border-radius: 3px

        &:hover:before
          background-color: #c5c5c5

        &.active:before
          background-color: #ef368b

    .footer--spacer
      flex-grow: 1

    .footer--social-media
      position: relative
      display: flex
      flex-grow: 0
      flex-shrink: 1
      flex-wrap: wrap
      align-items: center
      justify-content: center
      margin: 16px 0 0

      img.footer--social-media-item
        position: relative
        cursor: pointer
        width: 36px
        height: auto
        margin: 16px

        &:hover
          top: -2px
          left: -2px
          filter: drop-shadow(2px 2px 0 #ef368b)

    @include breakpoint(tablet)
      flex-direction: row
      align-items: flex-start

      .footer--logo
        margin: 0 32px 0 0

      .footer--menu
        align-items: flex-start

      .footer--social-media
        margin: 0

        img.footer--social-media-item
          margin: 8px

    @include breakpoint(laptop)
      .footer--social-media
        width: 360px

        img.footer--social-media-item
          margin: 16px

  .footer-bottom
    position: relative
    display: flex
    flex-direction: column
    padding-bottom: 16px
    margin: 0 auto
    max-width: 1200px
    width: 100%

    .footer-bottom--certificates
      margin: 32px 0

      h4
        text-align: center
        margin: 0 0 8px

      .footer-bottom--certificate-list
        display: flex
        flex-direction: row
        flex-wrap: wrap
        justify-content: center
        align-items: center
        gap: 16px

        .footer-bottom--certificate
          position: relative
          display: inline-block

    .footer-bottom--company-info
      p
        text-align: center
        font-size: 12px

    @include breakpoint(tablet)
      flex-direction: row-reverse
      align-items: center
      gap: 32px

      .footer-bottom--certificates
        margin-top: 0
        width: 360px
        text-align: center

      .footer-bottom--company-info
        flex-grow: 1

        p
          text-align: left
</style>
